export default [
  {
    icon: '🏪',
    title: 'Alterra Store',
    path: 'https://github.com/ubaidillahhf/alterra-store',
    source: 'https://github.com/ubaidillahhf/alterra-store',
    description: 'Simple rest API with Golang, use echo framework.',
  },
  {
    icon: '🐈',
    title: 'Pet Finder',
    path: 'https://github.com/ubaidillahhf/petapp-react',
    source: 'https://github.com/ubaidillahhf/petapp-react',
    description: 'Single page pet finder with react and parcel bundler.',
  },
  {
    icon: '🌙',
    title: 'React Tail',
    path: 'https://github.com/ubaidillahhf/starter-reactTail',
    source: 'https://github.com/ubaidillahhf/starter-reactTail',
    description: 'Boilerplate react with tailwind and parcel bundler ',
  },
  {
    icon: '🏞️',
    title: 'NFT Marketplace',
    source: 'https://github.com/ubaidillahhf/nft-eth',
    description: 'My research about create marketplace NFT',
  },
  {
    icon: '🫧',
    title: 'Clean Arch Go',
    path: 'https://github.com/satria-research/go-clarch',
    source: 'https://github.com/satria-research/go-clarch',
    description: 'Clean Architecture GoLang using Fiber',
  },
  {
    icon: '🧼',
    title: 'Clean Arch TS',
    path: 'https://github.com/satria-research/fastify-ts-oo-ca',
    source: 'https://github.com/satria-research/fastify-ts-oo-ca',
    description: 'Clean Architecture Typescript using fastify',
  },
]
